import React from "react";
import {CookieBanner, Footer, Header, Loading} from "../../components";

import {getLoginStatus} from "../../utility/authenticate";
import {useCmsData} from "../../utility/CmsDataProvider";
import {getCmsLanguage, getLanguage} from "../../utility/language";

import {useLoginState} from "../../api/login/loginState";
import '../../App.css';
import style from './style.module.css';

export default function PrivacyPolicy() {

    const {data, loading, error} = useCmsData()
    const {
        state: {loggedIn, token: {token} = {}},
    } = useLoginState()

    if (loading) {
        return <Loading></Loading>
    } else if (error) {
        return <h2>Error while loading CMS...</h2>
    }

    const footer = data?.asModel.reffromreflist('footer', "6ztqv4t7gksfukhvryugob3xewtxpfvfx2bme", true)

    data?.setLanguage(getCmsLanguage())
    footer?.setLanguage(getCmsLanguage())

    const policyTitle = footer?.asModel.str('privacypolicyTitle');
    const policyText = footer?.asModel.str('privacypolicyText');

    return (
        <div>
            <CookieBanner/>
            <Header solid/>
            <title>Bühler Virtual World</title>

            <div className={style.container}>
                <h1 className={style.title}>
                    {policyTitle}
                </h1>
                <pre className={style.paragraph}>
                    {policyText}
                </pre>
            </div>

            <Footer/>
        </div>
    )
}